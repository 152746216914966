import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
const axiosInstance = axios.create({
    baseURL: domain,
});

export default {
    create(payload) {
        return axiosInstance.post("v1_templates/v1/templates/create", payload);
    },
    update(id, payload) {
        return axiosInstance.put(`v1_templates/v1/templates/update/${id}`, payload);
    },
    remove(id) {
        return axiosInstance.delete(`v1_templates/v1/templates/remove/${id}`);
    },
    delete(id) {
        return axiosInstance.delete(`v1_templates/v1/templates/delete/${id}`);
    },
    get(id) {
        return axiosInstance.get(`v1_templates/v1/templates/${id}`);
    },
    paginate(query) {
        return axiosInstance.get(`v1_templates/v1/templates/paginate`, {params: query});
    },
    all() {
        return axiosInstance.get(`v1_templates/v1/templates/`);
    }
}
